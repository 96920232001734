// Customizable Area Start
import React,{useState} from 'react'
import { Grid, Button } from "@material-ui/core";
import '../../../user-profile-basic/src/Creatives/AddTrack.web.css'
import { Row, Col, Spin, Upload ,Progress} from "antd";

import './index.web.css'
import { ScanCover, PlusIcon , CheckTick, ScanProcessedCover, PlayIcon, VideoFrame, armIcon} from '../assets';
import { openNotification } from '../../../../components/src/Notification.web';
import ScanProcessController from './ScanProcessController.web';
import { nxzloader, pauseA } from '../../../dashboard/src/assets';

const renderToggle = (playing: any, uploadType: any, togglePlay: any) =>{
  return(
    <>
      <Grid item style={{ alignItems: 'center',justifyContent: 'center', paddingTop: uploadType == 'audio'? '5%' : '2%'}}>     
        <img src={playing? pauseA : PlayIcon} className={playing ? 'upload_pause_width' : 'upload_paly_width'} onClick={togglePlay}/>  
      </Grid>
    </>
  )
}

const UploadedAudioVideo = (audioFile: any, uploadType: any, uploadClassName: any, playing: any, togglePlay:  any, uploadMp3: any, uploadText: any) => {
  return(
    <>
    {
       audioFile? 
       <>
         <Grid item className={`${uploadType == 'audio'? 'Frame-135-audio': 'Frame-135'} justify-audio height-width margin-left-main upload_scan_gap`} style={{justifyContent: 'center', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                         
          <Grid  className={uploadClassName}  style={{borderTopLeftRadius: '25px',borderTopRightRadius: '25px',height: '82%' ,width: '102%',backgroundColor: '#141414'}}>
                        

           <audio id="audioPlayer" className="custom-audio">
             <source src={URL.createObjectURL(audioFile)} type="audio/mpeg" />
             Your browser does not support the audio tag.
           </audio>

           {renderToggle(playing, uploadType, togglePlay)}

           </Grid>
       
           <Grid item style={{borderBottomLeftRadius: '30px',borderBottomRightRadius: '30px', width: '100%', color: 'white', fontFamily: 'Inter', fontSize: '12px', fontWeight: 'bold', padding: '15px'}}>{uploadText}</Grid>

       </Grid>
       </>
       :

     <Upload
     name="uploadMP3"
     
     showUploadList={false}
     

     customRequest={(options: any) => {
       uploadMp3(options, uploadType)

     }}
   >
   <Grid item className={`${uploadType == 'audio'? 'Frame-135-audio': 'Frame-135'} justify-audio height-width margin-left-main upload_scan_gap`} style={{justifyContent: 'center', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                         
   <Grid  className={uploadClassName}  style={{borderTopLeftRadius: '25px',borderTopRightRadius: '25px',height: '82%' ,width: '102%',backgroundColor: '#141414'}}>
  

  {
   
   <Grid item style={{ alignItems: 'center',justifyContent: 'center', paddingTop: uploadType == 'audio'? '5%' : '2%'}}>     
     <img src={PlusIcon} style={{height: '43.3px', width: '43.3px'}}/>  
   </Grid>
   } 
   

   </Grid>
   
   <Grid item style={{borderBottomLeftRadius: '30px',borderBottomRightRadius: '30px', width: '100%', color: 'white', fontFamily: 'Inter', fontSize: '12px', fontWeight: 'bold', padding: '15px'}}>{uploadText}</Grid>

   </Grid>
   </Upload>
    }
    </>
  )
}

const renderAudioUIScreen = (audioFile: any, videoFile: any, uploadClassName: any, uploadType: any, uploadMp3: any, playing: any, togglePlay: any) =>{
  const uploadText = !videoFile ? <div>(Drag And Drop Or Click To Upload)</div> : null;

  return(
    <>
          {
              videoFile?
              (
              <Grid item style={{display: 'flex', alignItems: 'center',justifyContent: 'center'}}> 
                <video id="videoPlayer" className='video_frame_width' controls>
                  <source src={URL.createObjectURL(videoFile)} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Grid>
              )
              :

              (
              UploadedAudioVideo(audioFile, uploadType, uploadClassName, playing, togglePlay, uploadMp3, uploadText)
              )
}
    </>
   )
}

// const renderAudioUIScreen = (audioFile: any, videoFile: any, uploadClassName: any, uploadType: any, uploadMp3: any) =>{
//   return(
//     <>

//                {
//                     videoFile?
//                     (<Grid item style={{ alignItems: 'center',justifyContent: 'center'}}>     
//                        <img src={VideoFrame} style={{height: '82%', width: '100%'}}/>  
//                    </Grid>
//                     )
//                     :

//                    (
//                     uploadedAudioVideo(uploadMp3, uploadType, uploadClassName, audioFile, videoFile)
//                    )

//                   }

    
//     </>
//   )
// }

export default class ScanProcess extends ScanProcessController {
  render(){
    const {loading, scaned, audioFile, videoFile, recognization, fingerPrint, UploadFile, playing, count}: any = this.state;
    const uploadType = window.location.href.split('/')[4]

    
let uploadClassName=""
if(uploadType == 'audio'){
  uploadClassName="drag_and_drop_padding-audio"
} else if(videoFile){
  uploadClassName="drag_and_drop_padding-video-selected"
}else{
  uploadClassName="drag_and_drop_padding-video"
}

  return (
    <>
    {
     
         <Grid container className='UploadMainScroll'>
          
           <div className='main-div'>
            <Grid container item xs={12} md={12} lg={7} className= "flex-direction-on-medium" style={{justifyContent: 'center', marginTop: '30px',padding: '10px', display: 'flex', alignItems: 'center'}} >
                  <Grid>
                  {renderAudioUIScreen(audioFile, videoFile, uploadClassName, uploadType, this.uploadMp3, playing, this.togglePlay)} 
                  {this.state.validationText != '' && <Grid style={{color: 'red', fontSize: '14px', fontWeight: 'bold', display: 'flex', justifyContent: 'center'}}>{this.state.validationText}</Grid>}
                  </Grid>
                  <Grid item  className='margin-left-main' style={{width: '456px', marginTop: '10px'}}>
                     <div style={{position: 'relative'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                          <p style={{color: 'white', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'bold'}}>Recognition</p>
                          <p style={{color: 'white', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'bold'}} >{recognization}%</p>
                        </div>
                        <Progress percent={loading? count : recognization} showInfo={false} />
                        <img src={CheckTick}  style={{position: 'absolute', height: '26.7px', width: '26.7px', right: '0px'}}/>
                     </div>
                     <div style={{marginTop: '20px',position: 'relative'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                          <p style={{color: 'white', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'bold'}}>Fingerprint</p>
                          <p style={{color: 'white', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'bold'}} >{fingerPrint}%</p>
                        </div>
                        <Progress percent={loading? count : fingerPrint} showInfo={false} />
                        <img src={CheckTick}  style={{position: 'absolute', height: '26.7px', width: '26.7px', right: '0px'}}/>
                     </div>
                     <div style={{marginTop: '20px',position: 'relative'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                          <p style={{color: 'white', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'bold'}}>Upload</p>
                          <p style={{color: 'white', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'bold'}} >{UploadFile}%</p>
                        </div>
                        <Progress percent={loading? count : UploadFile } showInfo={false} />
                        <img src={CheckTick}  style={{position: 'absolute', height: '26.7px', width: '26.7px', right: '0px'}}/>
                     </div>
                  </Grid>
            </Grid>

            {
              scaned?
//margin-left-name-div
              <>
              <Grid item xs={12} md={12} lg = {5} className="hide-side-image-in-responsive">
                <div className='' style={{width: '100%', height: '100%', backgroundColor: 'rgb(5 4 4)',position: 'relative', overflow: 'hidden'}}>
                   
                   <img style={{borderTopLeftRadius: '100px',border: '5px solid #141414', width: '100%', height: '100%'}} src={ScanProcessedCover} />
                  
                   <div style={{position: 'absolute', bottom: '10%', width: '100%'}}>
                   <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                   <div className=' name-font-weight' style={{color: 'white',fontFamily: 'Inter', fontSize: '32px',textShadow: '-10px -4px 34px rgba(0,0,0,.8)'}}>IP Convergence</div>
                   <p className='name-font-weight' style={{color: 'white',fontFamily: 'Inter', fontSize: '32px',textShadow: '-10px -4px 34px rgba(0,0,0,.8)'}}> Calculating... </p>


                   <Button className=' buttons-font-weight' style={{backgroundColor:'red',padding: '15px 127px 15px 126px',borderRadius: '30px' , color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginBottom: '10px'}} onClick={this.afterNextClick} >NEXT</Button>
                   {/* <Button className='buttons-font-weight' style={{backgroundColor:'#212121',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px',boxShadow: '-50px 26px 20px 40px rgba(0,0,0,.8)', marginBottom: '10px'}}>SAVE</Button> */}
                   <Button variant="text" className='buttons-font-weight' style={{backgroundColor:'trasparent',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px'}} onClick={this.backClick}>BACK</Button>

                  </div>
                  </div>
                </div>
                
              </Grid>


              <Grid item xs={12} md={12} lg = {5} className="hide-buttons-in-large-screens">
                <div  style={{ backgroundColor: 'rgb(5 4 4)', overflow: 'hidden', display: 'flex', flexDirection: 'column',justifyContent: 'center', alignItems: 'center'}}>
                   <Button style={{backgroundColor:'red',padding: '15px 127px 15px 126px',borderRadius: '30px' , color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginLeft: '25px', marginBottom: '10px', marginTop: '20px'}}  onClick={this.afterNextClick} >NEXT</Button>
                   {/* <Button style={{backgroundColor:'#212121',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginBottom: '10px', marginLeft: '25px'}}>SAVE</Button> */}
                   <Button variant="text" style={{backgroundColor:'trasparent',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginBottom: '10px', marginLeft: '25px'}} onClick={this.backClick}>BACK</Button>

                </div>
                
              </Grid>
            </>
            :

            <>
            <Grid item xs={12} md={12} lg = {5} className="hide-side-image-in-responsive">
              <div className='' style={{width: '100%', height: '100%', backgroundColor: 'rgb(5 4 4)',position: 'relative', overflow: 'hidden' }}>
                <img style={{borderTopLeftRadius: '100px',border: '5px solid #141414', width: '100%', height: '100%'}} src={ScanCover} />
                
                <div style={{position: 'absolute', bottom: '10%', width: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <div className='name-font-weight' style={{color: 'white' ,fontFamily: 'Inter', fontSize: '32px',textShadow: '-10px -4px 34px rgba(0,0,0,.8)'}}>Its Your Sound,</div>
                <p className='name-font-weight' style={{color: 'white',fontFamily: 'Inter', fontSize: '32px',textShadow: '-10px -4px 34px rgba(0,0,0,.8)'}}> Protect It. <img style={{width: '40px', height: '40px', marginTop: '-15px'}} src={armIcon}></img></p>


                <Button className='buttons-font-weight' style={{backgroundColor:'red',padding: '15px 127px 15px 126px',borderRadius: '30px' , color: 'white' ,fontFamily: 'Karla', fontSize: '20px',fontWeight: 'bold', textTransform: 'capitalize', marginBottom: '10px'}} onClick={this.afterNextClick}>Next</Button>
                <Button variant="text" className='buttons-font-weight' test-id="scanprocess_back_id" style={{backgroundColor:'trasparent',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px',fontWeight: 'bold', textTransform: 'capitalize'}} onClick={this.backClick}>Back</Button>

              </div>
              </div>
              </div>
             </Grid>

              <Grid item xs={12} md={12} lg = {5} className="hide-buttons-in-large-screens">
                <div  style={{ backgroundColor: 'rgb(5 4 4)', overflow: 'hidden', display: 'flex', flexDirection: 'column',justifyContent: 'center', alignItems: 'center'}}>
                   <Button style={{backgroundColor:'red',padding: '15px 127px 15px 126px',borderRadius: '30px' , color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginLeft: '25px', marginBottom: '10px', marginTop: '20px',fontWeight: 'bold', textTransform: 'capitalize'}}  onClick={this.afterNextClick}>Next</Button>
                   <Button variant="text" style={{backgroundColor:'trasparent',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginBottom: '10px', marginLeft: '25px',fontWeight: 'bold', textTransform: 'capitalize'}} onClick={this.backClick}>Back</Button>

                </div>
                
              </Grid>
             </>
            }
            
            </div> 
         </Grid>
    
    }
    </>
   
  )
}
}
// Customizable Area End