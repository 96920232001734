// Customizable Area Start
import React, { useContext, useEffect, useRef } from "react";
import { Col, Row } from 'antd';
import millify from "millify";
import "./Waveform.css";
import { useHistory } from "react-router-dom";
import { Grid, Box, Hidden } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import '../../../../blocks/user-profile-basic/src/Common/TopSongs.web.css'
//@ts-ignore
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
import ChartsCarousel from "../Carousel/index.web";
import { plays, fullscreen, reshared, commentBlue, iconUp, defaultProfile, lockIcon, down, pauseA, playButton } from "blocks/dashboard/src/assets";
//@ts-ignore
import Waveform from "./Waveform";
import LineChart from "../PopUps/LineCharts.web";
import UnlockContentCommon from "../../../../blocks/DetailedDownloadHistory/src/StreamUnlock/Common/UnlockContentCommon.web";
import { unlockRedirectionCheck } from "../../../../blocks/DetailedDownloadHistory/src/StreamUnlock/Common/UnlockContentCommonController.web";

const Songs = ({
  videos,
  listOfSongs,
  playPlaylist,
  name,
  songIndex,
  setOpen,
  setDialogText,
  hideDownloadInPlaylist,
}: any) => {
  const {
    handlePlayList,
    handleAudioVideo,
    setHandleAudioVideo,
    playlistData,
  } = useContext(AudioContextApi);
  const history = useHistory();
  const subscriptionPlan = localStorage.getItem("subscription_plans");
  const token: any = localStorage.getItem("token");
  const userType: any = localStorage.getItem("user_type");

  const [selectedIndex, SetSelectedIndex] = React.useState("");
  const wavesurferRef: any = useRef<any>([]);
  useEffect(() => {
    if (Object.keys(playlistData).length > 0) {
      SetSelectedIndex(playlistData?.id)
    }
  }, [playlistData]);


  const topPlayMusic = async (
    item: any,
    index: any,
    songsList: any,
    trackName: any,
    id: any,
    wavesurferVal: any
  ) => {
    if (userType?.includes("listener")) {
      handlePlayList(item, index, songsList, trackName, id);
      // }
    } else {
      if (selectedIndex !== index) {
        setHandleAudioVideo(2);
        SetSelectedIndex(index);
        await handlePlayList(item, index, songsList, trackName, id);
      } else {
        if (handleAudioVideo === 1 || handleAudioVideo === 0) {
          setHandleAudioVideo(2);
          SetSelectedIndex(index);
          handlePlayList(item, index, songsList, trackName, id);
        } else {
          setHandleAudioVideo(1);
        }
      }
    }
  };
  const withOutToken = () => {
    history.push("/Welcome");
  };
  const onFullScreen = (e: any, item: any) => {

    e.preventDefault();
    e.stopPropagation();
    if (!token) {
      history.push('/Welcome')

    }
    // else if (name === "Playlists") {
    //   setDialogText("1")
    //   setOpen(true)
    // }
    else if (userType?.includes("listener") && subscriptionPlan == "NXZ") {
      setDialogText("3");
      setOpen(true);
    } else if (name === "Playlists" && item.attributes.owner_true) {
      handlePlayList(item, item.id, listOfSongs, "Playlists", 0);
    } else {
      history.push(`/home/expand/song/songId=${item.id}`);
    }
  };
  let opacity;
  const statusCheck = (name: any, status: any) => {
    if (name === "Playlists") {
      if (status === "private_playlist") {
        opacity = "0.5";
      } else {
        opacity = "1";
      }
    } else {
      opacity = "1";
    }
    return opacity;
  };
  const handleArrow = (icon: any) => {
    if (icon) {
      return iconUp;
    } else {
      return down;
    }
  };
  const showLockIcon = (bool: any) => {
    if (name === "Playlists" && bool) {
      return <img src={lockIcon} className="songs-lockicon" />;
    }
  };

  let classesArtwork: string = "topSong-without-artwork";
  const showArtWork = (attr: any) => {
    let defaultImg = "";

    if (name === "Playlists") {
      defaultImg = attr?.cover_image || defaultProfile;
      classesArtwork = "topSong-without-artwork"
    } else if (attr?.art_work) {
      //attr?.art_work
      defaultImg = attr?.art_work;
      classesArtwork = "topSong-without-artwork"

    } else {
      defaultImg = defaultProfile;
      classesArtwork = "topSongs-artwork0default-img"
    }
    return defaultImg;
  };
  const showTitle = (attr: any) => {
    let defaultImg = "";
    if (name === "Playlists") {
      defaultImg = attr?.name?.substring(0, 14) || "Unknown";
    } else {
      defaultImg = attr?.title?.substring(0, 14) || "Unknown";
    }
    return defaultImg;
  };

  const showPlayPause = (id: any) => {
    if (playlistData.id == id && handleAudioVideo === 2) {
      return pauseA;
    } else {
      return playButton;
    }
  };

  const returnHeight = (id: any) => {
    let heightpx = "";
    if (selectedIndex == id && handleAudioVideo === 2) {
      heightpx = "16px";
    } else {
      heightpx = "24px";
    }
    return heightpx;
  };

  const returnWidth = (id: any) => {
    let widthpx = "";
    if (selectedIndex == id && handleAudioVideo === 2) {
      widthpx = "17px";
    } else {
      widthpx = "24px";
    }
    return widthpx;
  };
  if(listOfSongs.length === 0 ){
    return(

    <div className="songs-listof-songs">
      <p className="songs-listof-child">No {name} Found</p>
    </div>
    )
  }else{
  return (
    <div className="songs-carousel-parent">
      {/* carousel */}
      <ChartsCarousel
        playlistData={playlistData}
        mainList={listOfSongs}
        songIndex={songIndex}
        name={name}
        handlePlayList={topPlayMusic}
        hideDownloadInPlaylist={hideDownloadInPlaylist}
      />
      {/* song List */}
      <Box className="songs-head-list">
        <Grid container className=" width_100 mt_3 songs-head-list-child">
          <Grid item xs={7} md={7} lg={8}>
            <Row className=" width_100">
              <Typography className="top-head">
                {listOfSongs ? listOfSongs?.length + " Tracks" : "Tracks"}
              </Typography>
            </Row>
          </Grid>
          <Grid
            item
            xs={4}
            md={4}
            lg={3}
            className="second-head-percentage dflex"
          >
            <Hidden xsDown>
              <Grid item xs={4}>
                <p className=" text_white1 top_size_16 hiddnDaysScore songs-head-style mt4">
                  24hrs%
                </p>
              </Grid>
              <Grid item xs={5}>
                <p className="text_white1 top_size_16 songs-head-style mt4">
                  7 Day %
                </p>
              </Grid>
              <Grid item xs={3} className="hiddnDaysGraph align-center">
                <p className="text_white1 top_size_16 hiddnDaysGraph songs-head-style mt4">
                  Last 7 Days
                </p>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
          <Box
            className={
              videos
                ? "charts-videos-height"
                : playPlaylist
                  ? "charts-audio-height mostly-customized-scrollbar"
                  : "charts-tabs-height mostly-customized-scrollbar"
            }
          >
            {listOfSongs?.map((item: any, index: any) => {
              return (
                <Col
                  span={24}
                  key={item.id}
                  onClick={() =>
                    token
                      ? unlockRedirectionCheck(topPlayMusic, [
                        item,
                        item.id,
                        listOfSongs,
                        name,
                        index,
                        wavesurferRef], item
                      )
                      : unlockRedirectionCheck(withOutToken, "", item)
                  }
                  className="musicRow"
                >
                  <Grid
                    container
                    className="main-container-space songs-padding"
                  >
                    <Grid
                      item
                      md={3}
                      lg={3}
                      xs={3}
                      sm={4}
                      className="profileDetailBlock"
                    >
                      <div className=" width_100 dflex-align-center">
                      {item.type === "list_ad_campaign"?
                      <div className="ad-div-round mr_1 ml_05"/>
                      :
                        <div>
                          <Row className="sn-grid mr_1 ml_05">
                            {index < 9 ? "0" + Number(index + 1) : index + 1}
                          </Row>
                          <img
                            src={handleArrow(item.attributes.arrow)}
                            className="icon-up mr_05 ml_05"
                          />
                        </div>
                       }

                        <div className="position-relative">
                          <div style={{
                            borderRadius: "8px",
                            border: "solid 1px gray",
                            opacity: statusCheck(
                              item.attributes.name,
                              item.attributes.status
                            ),
                            width: "50px",
                            height: "50px",
                            display: "flex"
                          }}
                            className="song-cover mr_1 cursor_pointer"
                          >
                            <img className={classesArtwork}

                              src={showArtWork(item?.attributes)}
                            />
                            <UnlockContentCommon trackData={item} padding='5px' height="95%" width="80%" />
                          </div>
                        </div>
                        <div>
                          <Row>
                            {item.type == "list_ad_campaign" ?
                              <span className="align-oneLine">
                                <p className="ad-placement-text-font">Ad: </p>
                                <Row
                                  justify="start"
                                  className=" width_100 artist-name name_hiddne text_white cursor_pointer"
                                >

                                  {showTitle(item?.attributes)}
                                </Row>
                                </span>
                          :
                                <Row
                                  justify="start"
                                  className=" width_100 artist-name name_hiddne text_white cursor_pointer"
                                >

                                  {showTitle(item?.attributes)}
                                </Row>
                            }
                                <Row
                                  justify="start"
                                  className="width_100 text_gray font_12  cursor_pointer title-name artist-name-width"
                                >
                                  {item?.attributes?.artist_name || "Unknown"}
                                </Row>{" "}
                              </Row>
                        </div>
                      </div>
                    </Grid>
                    <Col
                      xs={6}
                      className="HiddnFullScreen  cursor_pointer hours-list "
                    >
                      <Row justify="end" className="iconDelete top-10">
                        <img
                          src={fullscreen}
                          onClick={(e) => unlockRedirectionCheck(onFullScreen, [e, item], item)}
                          className=" ml-1 mr_1"
                          height="28px"
                          width="28px"
                        />
                      </Row>
                    </Col>
                    <Grid
                      item
                      md={1}
                      xs={2}
                      sm={4}
                      lg={3}
                      className="playButtonContain"
                    >
                      <Hidden xsDown>
                        <Row className=" width_100">
                          <Col span={24} className="songs-play-pause">
                            <img
                              src={showPlayPause(item?.id)}
                              height={returnHeight(item?.id)}
                              width={returnWidth(item?.id)}
                              className="play_whites mr_1 cursor_pointer hiddnGraph"
                            />
                            {name !== "Playlists" && (
                              <Hidden mdDown>
                                <div className="width100">
                                  <div>
                                    <Waveform
                                      url={item?.attributes?.track_file}
                                      id={item?.id}
                                      height={40}
                                    />
                                  </div>
                                </div>
                              </Hidden>
                            )}
                          </Col>
                        </Row>
                      </Hidden>
                    </Grid>
                    <Grid item sm={3} md={3} lg={2} className="iconButton">
                      <div className="display-play_dark_back icons-padding dflex-align-center">
                        <Col className="colButton width_100">
                          <div className="iconContent dflex-align-center">
                            <img
                              className="iconImg"
                              src={plays}
                              height="24px"
                              width="24px"
                            />
                            <Box className="play-times-charts-songs iconContent">
                              {millify(item?.attributes?.play_count)}
                            </Box>
                          </div>
                        </Col>
                        <Col className="colButton width_100">
                          <div className="iconContent dflex-align-center">
                            <img
                              className="iconImg"
                              src={reshared}
                              height="24px"
                              width="24px"
                            />
                            <Box className="play-times iconContent">
                              {" "}
                              {/* {millify(item?.attributes?.repost_count)} */}
                            </Box>
                          </div>{" "}
                        </Col>
                        <Col className="colButton width_100">
                          <div className="iconContent dflex-align-center">
                            <img
                              className="iconImg"
                              src={commentBlue}
                              height="18px"
                              width="20px"
                            />
                            <Box className="play-times-charts-songs iconContent">
                              {item?.attributes?.comments_count
                                ? millify(item?.attributes?.comments_count)
                                : "0"}{" "}
                            </Box>
                          </div>
                        </Col>
                      </div>
                    </Grid>
                    <Grid
                      item
                      sm={5}
                      md={5}
                      lg={4}
                      className="hidden_percentage"
                    >
                      <LineChart
                        seven_days={
                          item?.attributes?.last_seven_days_percentage
                        }
                        twenty_four_days={
                          item?.attributes?.last_twenty_four_hours_percentage
                        }
                        seven_days_graph={
                          item?.attributes?.last_seven_days_graph
                        }
                        fullscreen={fullscreen}
                        onFullScreen={(e: any) => unlockRedirectionCheck(onFullScreen, [e, item], item)}
                      />
                    </Grid>
                  </Grid>
                </Col>
              );
            })}
          </Box>
      </Box>
    </div>
  );
}
};

export default Songs;
// Customizable Area End
