// Customizable Area Start

import { Typography, Input, Row } from 'antd';
import {
    Box,
    Button,
    styled,
    withStyles,
    Slider,
    Hidden
} from "@material-ui/core";
import React from 'react'
import { SearchOutlined } from "@ant-design/icons";
import {plusicon, backarrow, check} from '../assets';
import DisplayAdController, { ProfileTrack } from './DisplayAdController.web';
import { defaultProfile, nxzloader } from 'blocks/dashboard/src/assets';
import './TracksAd.css'

const StyledSlider = withStyles({
    root: {
        color: '#fff',
        // height: '15px',
        padding: '0px',


    },
    thumb: {

        '&:focus, &:hover, &$active': {
            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
        },
        track: {
            height: "15px",
            background: "#141414",
            borderRadius: "15px"
        },
        label: {
            '&::before': {
                content: "",
                width: "20px",
                height: "20px",
                background: "red"
            },
        }

    },
})(Slider)
const Inputboxwrapper = styled(Box)({
    borderRadius: "10px",
    padding: "10px",
    width: "100%",
    border: "1px solid #212121",
    background: "#141414",
    position: "relative",
    "& .ant-input": {
        backgroundColor: "#141414",
        width: "88%",
        color: "white",
    },
    "& .ant-input-affix-wrapper": {
        background: "none",
        border: "unset",
    },
    "@media(max-width: 1024px)": {
        width: "100%",
        "& .ant-input": {
            backgroundColor: "#212121",
            width: "50%",
        },
    },
});
const LeftSideSingletrackboxContainer = styled(Box)({
    height: "70px",
    display: "flex",
    borderRadius: "8px",
    cursor: "pointer",
    alignItems: "center",
    gap: "10px",
    objectFit: "contain",
    color: "white",
    justifyContent: "space-between",

});
const Tracknames = styled(Box)({
    whiteSpace: "nowrap" as "nowrap",
    color: "#ffffff",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 700,
    lineHeight: "16.94px",
    letterSpacing: "normal",
    "@media(max-width: 400px)": {
        // whiteSpace: "unset",
        // fontSize: "18px"
    },
});
const TrackAdvertisementContainer = styled(Box)({
    width: "100%",
    // height: "100vh",
    height: "90vh",
    paddingLeft: "35px",

    // marginBottom: "1rem",
    paddingBottom: "1rem",
    // overflowY: "scroll",
    display: "flex",
    justifyContent: "space-between",
    background: "black",
    gap: "20px",
    // marginTop: "30px",
    paddingTop: "30px",

    paddingRight: "10px",
    "@media(max-width: 1200px)": {
        display: "grid"
    },
    "@media(max-width: 600px)": {
        justifyContent: "normal"
    },
})
const StyledsliderMainbox = styled(Box)({
    width: "75%",
    "@media(max-width: 1348px)": {
        width: "70%"
    },
    "@media(max-width: 1200px)": {
        width: "85%"
    },
    "@media(max-width: 600px)": {
        width: "73%"
    },
})
export default class TracksAdd extends DisplayAdController {
    renderProfileTrackCard = (item: ProfileTrack, index: number) => {
        return (
            <LeftSideSingletrackboxContainer
                key={index}
            >
                <Box>
                    <Box style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                    <div style={this.state.Addtype == "Video" ?
                                webStyle.videoImage
                                : webStyle.leftimage}
                              >
                        <img
                            src={item.attributes.art_work||defaultProfile}
                            className={item.attributes.art_work ?
                                "push-result-art-work":
                                "default-profile-mediaDMCP"}
                             />
</div>

                    </Box>
                </Box>
                <Box style={{ display: "flex", flexDirection: "column", width: "90%" }}>
                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                        <Box>
                            <Tracknames className="tracks_songs_text">
                                {item.attributes.title || 'Unknown'}
                                <span className="Jason-Derulo">
                                </span>
                            </Tracknames>
                            <Box>
                                <span style={webStyle.splittexts}>
                                    {item.attributes.artist_name}
                                </span>
                            </Box>

                        </Box>
                        <Box>
                            <Box style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                            }}>
                                <img src={this.state.selectTrack == item.id ? check : plusicon} style={webStyle.plusicon}
                                    onClick={() => this.selectAnytrack(item.id)}
                                    data-test-id="selectanytrackid" />
                            </Box>
                        </Box>

                    </Box>

                </Box>
            </LeftSideSingletrackboxContainer>
        )
    }

    renderDataCard=()=>{
        return(
            this.state.profileTrackData.length > 0 ?
                                this.state.profileTrackData.map((item: ProfileTrack, index: number) => (
                                    this.renderProfileTrackCard(item,index)
                                ))
                                :
                                <div style={{ fontSize: "20px", fontWeight: "bold", color: "white", fontFamily: "Inter", display: "flex", justifyContent: "center", paddingTop: "120px" }} data-test-id="iiii">
                                    No Data Found
                                </div>
        )
    }
    renderSubButtonsTracks = () => {
        return (
            this.state.Addtype == "Beat" && <Box style={webStyle.Rightsidetabsbutton}>
                <Box style={webStyle.tabbox}>
                    <Button
                        onClick={() => this.selecteTracksType("Beat")}
                        data-test-id="selectedtracksbeatsid"
                        style={this.state.trackstype == "Beat" ? webStyle.tabactiveaudiovideobutton : webStyle.tabaudiovideobutton}
                    >
                        Beats
                    </Button>
                </Box>
                <Box style={{ width: "50%", display: 'flex', justifyContent: 'center' }}>
                    <Button
                        style={this.state.trackstype == "Hook" ? webStyle.tabactiveaudiovideobutton : webStyle.tabaudiovideobutton}
                        onClick={() => this.selecteTracksType("Hook")}
                        data-test-id="selectedtrackshooksid"
                    >
                        Hooks
                    </Button>
                </Box>
                <Box style={{ width: "50%", display: 'flex', justifyContent: 'center' }}>
                    <Button
                        style={this.state.trackstype == "Verse" ? webStyle.tabactiveaudiovideobutton : webStyle.tabaudiovideobutton}
                        onClick={() => this.selecteTracksType("Verse")}
                        data-test-id="selectedtracksversesid"
                    >
                        Verses
                    </Button>
                </Box>
            </Box>
        )
    }



    profileScreen = () => {
        return (
            <>
                {(!this.state.isMobile || this.state.activeStepmobile == "2") && <Box style={{ display: "flex", flexDirection: "column", gap: "30px", width: "100%",overflow:'hidden auto',
    scrollbarWidth:'none' }} className="scrollerWidth">
                    <Box style={{ display: 'flex', gap: "4px", alignItems: "center" }}>
                        <Hidden only={["lg", "md", "sm", "xl"]}>
                            <img src={backarrow} style={{ height: "26px", width: "26px" }} onClick={this.onbackMobile.bind(this, "1")} />
                        </Hidden><Typography style={webStyle.bluetext}>Target: <span style={webStyle.selecttext}>Profiles</span></Typography>
                    </Box>

                    <Typography style={webStyle.greytext}>Select the Ad Type you would like to promote.</Typography>
                    <Box style={webStyle.Rightsidetabsbutton}>
                        <Box style={webStyle.tabbox}>
                            <Button
                                style={this.state.Addtype == "Beat" ? webStyle.tabactiveaudiovideobutton : webStyle.tabaudiovideobutton}
                                onClick={() => this.selectAddType("Beat")}
                                data-test-id="selectedaddtypetracksid"
                                disabled={this.state.playerAdcontentType==="Videos"}
                            >
                                Tracks
                            </Button>
                        </Box>
                        <Box style={{ width: "50%", display: 'flex', justifyContent: 'center' }}>
                            <Button
                                onClick={() => this.selectAddType("Song")}
                                data-test-id="selectedaddtypesongsid"
                                style={this.state.Addtype == "Song" ? webStyle.tabactiveaudiovideobutton : webStyle.tabaudiovideobutton}
                                disabled={this.state.playerAdcontentType==="Videos"}
                            >
                                Songs
                            </Button>
                        </Box>
                        <Box style={{ width: "50%", display: 'flex', justifyContent: 'center' }}>
                            <Button
                                onClick={() => this.selectAddType("Video")}
                                data-test-id="selectedaddtypevideosid"
                                style={this.state.Addtype == "Video" ? webStyle.tabactiveaudiovideobutton : webStyle.tabaudiovideobutton}
                            >
                                Videos
                            </Button>
                        </Box>
                    </Box>
                    {this.renderSubButtonsTracks()}
                    <Inputboxwrapper>
                        <Input
                            test-id="test_search_term_id_handle_search_input"
                            onChange={(e) => this._getProfileTracks(this.state.Addtype, e.target.value)}
                            value={this.state.searchText}
                            className="testCtrlVideo"
                            prefix={

                                <SearchOutlined
                                    className="text_white1"
                                />
                            }
                            placeholder="Search"
                        />
                    </Inputboxwrapper>
                    <Box>
                        {this.state.isLoading ? (

                            <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }} data-test-id="ooooo">
                                <img src={nxzloader} style={{ width: "5%" }} />
                            </Row>

                        ) :
                            this.renderDataCard()
                        }
                    </Box>
                    <Box style={{ paddingTop: "10px", paddingBottom: "5px" }}>
                        <Hidden only={["lg", "md", "sm", "xl"]}>
                            <Button onClick={this.setActiveStepmobile.bind(this, "3")} data-test-id="setactivemobileid3" style={{ background: "#FF0501", color: "white", width: "100%", borderRadius: "60px", height: "60px", fontFamily: "Inter", fontSize: "22px", textTransform: "capitalize", fontWeight: 700 }}>NEXT</Button>
                        </Hidden>
                    </Box>
                </Box>}
            </>
        )
    }
    bugdetDurationScreen = () => {
        return (<>
            {(!this.state.isMobile || this.state.activeStepmobile == "3") && <Box className='trackAdBudget'>
                <Box style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                    <Hidden only={["lg", "md", "sm", "xl"]}>
                        <img src={backarrow} style={{ height: "26px", width: "26px" }} onClick={this.onbackMobile.bind(this, "2")} />
                    </Hidden>  <Typography style={webStyle.selectaudiencetext}>Budget & Duration</Typography>
                </Box>
                <Box style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
                    <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                        <Typography style={webStyle.budgettext}>Total Budget</Typography>
                        <Typography style={webStyle.budegtgreytext}>Select the total budget amount you would <br /> like to spend for this campaign.</Typography>
                    </Box>

                    <StyledsliderMainbox>
                        <StyledSlider
                            value={this.state.totalbudget}
                            className='slideradd'
                            valueLabelDisplay='on'
                            defaultValue={5000}
                            step={100}
                            min={20}
                            max={10000}
                            onChange={this.handleBudget}
                        />
                    </StyledsliderMainbox>
                </Box>
                {(this.state.Addtype == "Videos" && this.state.pushType == "DisplayAd") && <Box style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
                    <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                        <Typography style={webStyle.cpvtext}>Cost Per Play (CPV)</Typography>
                        <Typography style={webStyle.budegtgreytext}>Select the total amount you want to   <br /> pay per view.</Typography>
                    </Box>

                    <StyledsliderMainbox>
                        <StyledSlider
                            step={0.10}
                            min={0.10}
                            max={10}
                            value={this.state.cpv}
                            onChange={this.handleCpv}
                            className='slideradd'
                            valueLabelDisplay='on'
                        />
                    </StyledsliderMainbox>
                </Box>}
                {((this.state.pushType == "playerAd" && this.state.Addtype !== "Videos") || (this.state.pushType == "DisplayAd" && this.state.Addtype !== "Videos")) && <Box style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
                    <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                        <Typography style={webStyle.budgettext}>Cost Per Play (CPP)</Typography>
                        <Typography style={webStyle.budegtgreytext}>Select the total amount you want to <br /> pay per play</Typography>
                    </Box>

                    <StyledsliderMainbox>
                        <StyledSlider
                            value={this.state.cpp}
                            step={0.10}
                            min={0.02}
                            max={10}
                            onChange={this.handleCpp}
                            className='slideradd'
                            valueLabelDisplay='on'
                        />
                    </StyledsliderMainbox>
                </Box>}
                {this.state.pushType == "playerAd" ? <>
                    {this.state.Addtype == "Tracks" && <Box style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
                        <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <Typography style={webStyle.budgettext}>Cost Per Download (CPD)</Typography>
                            <Typography style={webStyle.budegtgreytext}>SSelect the amount you want to pay  <br /> when users download.</Typography>
                        </Box>

                        <StyledsliderMainbox>
                            <StyledSlider
                                value={this.state.cpd}
                                step={0.10}
                                min={0.02}
                                max={10}
                                onChange={this.handleCpd}
                                className='slideradd'
                                valueLabelDisplay='on'
                            />
                        </StyledsliderMainbox>
                    </Box>}
                    {this.state.Addtype == "Videos" && <Box style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
                        <Box style={{ flexDirection: "column", display: "flex", gap: "10px" }}>
                            <Typography style={webStyle.budgettext}>Cost Per Play (CPV)</Typography>
                            <Typography style={webStyle.budegtgreytext}>Select the total amount you want to   <br /> pay per view.</Typography>
                        </Box>

                        <StyledsliderMainbox>
                            <StyledSlider
                                value={this.state.cpv}
                                step={0.10}
                                min={0.02}
                                className='slideradd'
                                valueLabelDisplay='on'
                                max={10}
                                onChange={this.handleCpv}
                            />
                        </StyledsliderMainbox>
                    </Box>}

                    <Box style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
                        <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <Typography style={webStyle.budgettext}>Cost Per Follow (CPF)</Typography>
                            <Typography style={webStyle.budegtgreytext}>Select the amount you want to pay   <br /> when users follow you</Typography>
                        </Box>

                        <StyledsliderMainbox>
                            <StyledSlider
                                value={this.state.cpf}
                                step={0.10}
                                min={0.02}
                                max={10}
                                onChange={this.handleCpf}
                                className='slideradd'
                                valueLabelDisplay='on'
                            />
                        </StyledsliderMainbox>
                    </Box>
                    {(this.state.Addtype == "Songs" || this.state.Addtype == "Videos") && <Box style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
                        <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <Typography style={webStyle.budgettext}>Cost Per Share (CPS)</Typography>
                            <Typography style={webStyle.budegtgreytext}>Select the amount you want to pay   <br /> when users shares your content</Typography>
                        </Box>

                        <StyledsliderMainbox>
                            <StyledSlider
                                value={this.state.cps}
                                step={0.10}
                                min={0.02}
                                max={10}
                                onChange={this.handleCps}
                                className='slideradd'
                                valueLabelDisplay='on'
                            />
                        </StyledsliderMainbox>
                    </Box>
                    }

                </> : <>
                    <Box style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
                        <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <Typography style={webStyle.budgettext}>Cost Per Impression (CPI)</Typography>
                            <Typography style={webStyle.budegtgreytext}>Select the total amount you want to <br /> pay per impression</Typography>
                        </Box>

                        <StyledsliderMainbox>
                            <StyledSlider
                                className='slideradd'
                                value={this.state.cpi}
                                step={0.10}
                                min={0.02}
                                max={10}
                                onChange={this.handleCpi}
                                valueLabelDisplay='on'
                            />
                        </StyledsliderMainbox>
                    </Box>
                    <Box style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
                        <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <Typography style={webStyle.budgettext}>Cost Per Click (CPC)</Typography>
                            <Typography style={webStyle.budegtgreytext}>Select the amount you want to pay when <br />
                                users discover and click your PUSH</Typography>
                        </Box>

                        <StyledsliderMainbox>
                            <StyledSlider
                                className='slideradd'
                                value={this.state.cpc}
                                step={0.10}
                                min={0.02}
                                max={10}
                                onChange={this.handleCpc}
                                valueLabelDisplay='on'
                            />
                        </StyledsliderMainbox>
                    </Box>
                </>}



                <Box style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
                    <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                        <Typography style={webStyle.budgettext}>Campaign Duration</Typography>
                        <Typography style={webStyle.budegtgreytext}>Select how many days you would like your <br />
                            campaign to run until stopped.</Typography>
                    </Box>

                    <StyledsliderMainbox>
                        <StyledSlider
                            value={this.state.budgetDuration}
                            step={1}
                            min={1}
                            max={10}
                            onChange={this.handleBudgetDuration}
                            className='slideradd'
                            valueLabelDisplay='on'
                        />
                    </StyledsliderMainbox>
                </Box>
                <Box style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
                    <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                        <Typography style={webStyle.budgettext}>Payment</Typography>
                        <Typography style={webStyle.budegtgreytext}>Select how you want to pay for this campaign.</Typography>
                    </Box>

                    <Box style={{ display: "flex", gap: "8px" }}>
                        <Box style={{
                            width: "50%",
                            height: "68px",
                            borderRadius: "7px",
                            border: "1px solid #212121",
                            background: this.state.PaymentMethod == "wallet" ? "#3959F9" : "#141414",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"

                        }}
                            onClick={() => this.selectPaymentMethod("wallet")}
                            data-test-id="paymentmethodwalletid"
                        >
                            <Typography style={{ ...webStyle.selecttext, cursor: "pointer" }}>
                                Wallet
                            </Typography>
                        </Box>
                        <Box style={{
                            width: "50%",
                            height: "68px",
                            borderRadius: "7px",
                            border: "1px solid #212121",
                            background: this.state.PaymentMethod == "card" ? "#3959F9" : "#141414",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                            onClick={() => this.selectPaymentMethod("card")}
                            data-test-id="paymentmethodcardid">
                            <Typography style={{ ...webStyle.selecttext, cursor: "pointer" }}>
                                Card
                            </Typography>
                        </Box>
                    </Box>
                    <Typography style={webStyle.redtext}>Wallet Balance:<span style={webStyle.dollarsmall}> $0<span style={webStyle.dollartext}>40</span></span></Typography>
                </Box>
                <Box style={{ display: "flex", flexDirection: "column", gap: "30px" }}>

                    <Typography style={webStyle.budgettext}>Estimated Placements</Typography>
                    <Typography style={webStyle.budegtgreytext}>Estimated Amount of Placements you could obtain from this campaign.</Typography>
                    <Typography style={webStyle.selectaudiencetext}>0 <span style={webStyle.imptext}>imp</span></Typography>
                </Box>
                <Box style={{ display: "flex", flexDirection: "column", gap: "30px" }}
                    onClick={this.submitAdData}
                    data-test-id="submit-ad-data"
                >

                    <Button style={{ background: "#FF0501", color: "white", width: "100%", borderRadius: "60px", height: "60px", fontFamily: "Inter", fontSize: "22px", textTransform: "initial", fontWeight: 700 }}>
                        Pay and Push
                    </Button>
                </Box>

            </Box>}
        </>)
    }
    displayAdcontent = () => {
        return (
            <>
                <Box style={{ display: 'flex', flexDirection: "column", gap: "22px" }}>
                    <Typography style={webStyle.bluetext}>Target:<span style={webStyle.selecttext}> Placement Location</span></Typography>
                    <Typography style={webStyle.greytext}>Select the <span style={webStyle.smallbluetext}>content types</span> you want you <br />
                        advertisement to show over.</Typography>
                    <Box style={{ display: "flex", gap: "8px" }}>
                        <Box style={{
                            width: "50%",
                            height: "68px",
                            borderRadius: "7px",
                            border: "1px solid #212121",
                            background: this.state.contentType == "Profiles" ? "#3959F9" : "#141414",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer"
                        }}
                            onClick={() => this.selectContentType("Profiles")}
                            data-test-id="contenttypeprofileid"
                        >
                            <Typography style={webStyle.selecttext}>

                                Profiles
                            </Typography>
                        </Box>
                        <Box style={{
                            width: "50%",
                            height: "68px",
                            borderRadius: "7px",
                            cursor: "pointer",
                            border: "1px solid #212121",
                            background: this.state.contentType == "Playlists" ? "#3959F9" : "#141414",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                            onClick={() => this.selectContentType("Playlists")}
                            data-test-id="contenttypePlaylistsid">

                            <Typography style={webStyle.selecttext}>
                                Playlists
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box style={{ display: 'flex', flexDirection: "column", gap: "22px" }}>
                    <Typography style={webStyle.bluetext}>Target:<span style={webStyle.selecttext}> Account Type</span></Typography>
                    <Typography style={webStyle.greytext}>Select the <span style={webStyle.smallbluetext}>Account Types</span> you want your <br /> Advertisement to be shown to.</Typography>
                    <Box style={webStyle.alltypesbuttonbox}>
                        <Box style={{ display: "flex", gap: "8px" }}>
                            <Box style={{
                                width: "50%",
                                height: "68px",
                                borderRadius: "7px",
                                border: "1px solid #212121",
                                background: this.state.AccountType == "AllTypes" ? "#3959F9" : "#141414",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                                onClick={() => this.selectAccountType("AllTypes")}
                                data-test-id="alltypesaccountid">

                                <Typography style={webStyle.selecttext}>
                                    All Types
                                </Typography>
                            </Box>
                            <Box style={{
                                width: "50%",
                                height: "68px",
                                borderRadius: "7px",
                                border: "1px solid #212121",
                                background: this.state.AccountType == "Listeners" ? "#3959F9" : "#141414",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                                onClick={() => this.selectAccountType("Listeners")}
                                data-test-id="Listenerstypesaccountid">

                                <Typography style={webStyle.selecttext}>
                                    Listeners
                                </Typography>
                            </Box>
                        </Box>
                        <Box style={{ display: "flex", gap: "8px" }}>
                            <Box style={{
                                width: "50%",
                                height: "68px",
                                borderRadius: "7px",
                                border: "1px solid #212121",
                                background: this.state.AccountType == "Creatives" ? "#3959F9" : "#141414",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                                onClick={() => this.selectAccountType("Creatives")}
                                data-test-id="Creativestypesaccountid">
                                <Typography style={webStyle.selecttext}>
                                    Creatives
                                </Typography>
                            </Box>
                            <Box style={{
                                width: "50%",
                                height: "68px",
                                borderRadius: "7px",
                                border: "1px solid #212121",
                                background: this.state.AccountType == "Labels" ? "#3959F9" : "#141414",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                                onClick={() => this.selectAccountType("Labels")}
                                data-test-id="Labelstypesaccountid">
                                <Typography style={webStyle.selecttext}>
                                    Labels
                                </Typography>
                            </Box>

                        </Box>
                    </Box>

                </Box></>
        )
    }
    songsVideosscreen = () => {
        return (
            <>
                {this.state.pushType == 'playerAd' ? <>
                    {this.state.Addtype !== "Videos" &&
                        <Box style={{ display: 'flex', flexDirection: "column", gap: "22px" }}>
                            <Typography style={webStyle.bluetext}>Target:<span style={webStyle.selecttext}> Content Type</span></Typography>
                            <Typography style={webStyle.greytext}>Select the <span style={webStyle.smallbluetext}>content types</span> you would like your advertisement<br />
                                to target for this campaign</Typography>
                            <Box style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                                <Box style={{ display: "flex", gap: "8px" }}>
                                    <Box style={{
                                        width: "33.33%",
                                        height: "68px",
                                        borderRadius: "7px",
                                        border: "1px solid #212121",
                                        background: this.state.playerAdcontentType == "All" ? "#3959F9" : "#141414",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        cursor: "pointer"
                                    }}
                                        onClick={() => this.selectPlayerAdcontentType("All")}
                                        data-test-id="playeradcontenttypeallid"
                                    >
                                        <Typography style={webStyle.selecttext}>

                                            All
                                        </Typography>
                                    </Box>
                                    <Box style={{
                                        height: "68px",
                                        borderRadius: "7px",
                                        cursor: "pointer",
                                        width: "33.33%",
                                        border: "1px solid #212121",
                                        background: this.state.playerAdcontentType == "Beats" ? "#3959F9" : "#141414",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                        onClick={() => this.selectPlayerAdcontentType("Beats")}
                                        data-test-id="playeradcontenttypebeatsid">

                                        <Typography style={webStyle.selecttext}>
                                            Beats
                                        </Typography>
                                    </Box>
                                    <Box style={{
                                        height: "68px",
                                        borderRadius: "7px",
                                        width: "33.33%",
                                        cursor: "pointer",
                                        border: "1px solid #212121",
                                        background: this.state.playerAdcontentType == "Hooks" ? "#3959F9" : "#141414",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                        onClick={() => this.selectPlayerAdcontentType("Hooks")}
                                        data-test-id="playeradcontenttypehooksid">

                                        <Typography style={webStyle.selecttext}>
                                            Hooks
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box style={{ display: "flex", gap: "8px" }}>
                                    <Box style={{
                                        width: "33.33%",
                                        height: "68px",
                                        borderRadius: "7px",
                                        border: "1px solid #212121",
                                        background: this.state.playerAdcontentType == "Verses" ? "#3959F9" : "#141414",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        cursor: "pointer"
                                    }}
                                        onClick={() => this.selectPlayerAdcontentType("Verses")}
                                        data-test-id="playeradcontenttypeversesid"
                                    >
                                        <Typography style={webStyle.selecttext}>
                                            Verses
                                        </Typography>
                                    </Box>
                                    <Box style={{
                                        height: "68px",
                                        borderRadius: "7px",
                                        cursor: "pointer",
                                        width: "33.33%",
                                        border: "1px solid #212121",
                                        background: this.state.playerAdcontentType == "Songs" ? "#3959F9" : "#141414",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                        onClick={() => this.selectPlayerAdcontentType("Songs")}
                                        data-test-id="playeradcontenttypesongsid">

                                        <Typography style={webStyle.selecttext}>
                                            Songs
                                        </Typography>
                                    </Box>
                                    <Box style={{
                                        height: "68px",
                                        borderRadius: "7px",
                                        width: "33.33%",
                                        cursor: "pointer",
                                        border: "1px solid #212121",
                                        background: this.state.playerAdcontentType == "Videos" ? "#3959F9" : "#141414",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                        onClick={() => {
                                            
                                            this.selectPlayerAdcontentType("Videos")
                                        }}
                                        data-test-id="playeradcontenttypevideosid">

                                        <Typography style={webStyle.selecttext}>
                                            Videos
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>

                        </Box>}    </> : <>
                    {this.displayAdcontent()}

                </>}
            </>
        )
    }
    render() {
        return (
            <TrackAdvertisementContainer className='displayad' >

                {this.state.activeStepmobile == "1" && <Box style={{
                overflow:'hidden auto',
    scrollbarWidth:'none' 
            }} className="scrollerWidth">
                    <Box style={{ width: "100%", display: "flex", flexDirection: "column", gap: "60px",}}>
                        <Typography style={webStyle.selectaudiencetext}>Select Audience</Typography>
                        <Box style={{ display: 'flex', flexDirection: "column", gap: "22px" }}>
                            <Typography style={webStyle.bluetext}>Select: <span style={webStyle.selecttext}>PUSH Type</span></Typography>
                            <Typography style={webStyle.greytext}>Select how you would like to push.</Typography>
                            <Box style={{ display: "flex", gap: "8px" }}>
                                <Box style={{
                                    width: "50%",
                                    height: "68px",
                                    borderRadius: "7px",
                                    border: "1px solid #212121",
                                    background: this.state.pushType == "playerAd" ? "#3959F9" : "#141414",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                                    onClick={() => this.selectPushType("playerAd")}
                                    data-test-id="playeraddid"
                                >
                                    <Typography style={webStyle.selecttext}>
                                        Player Ad
                                    </Typography>
                                </Box>
                                <Box style={{
                                    width: "50%",
                                    height: "68px",
                                    borderRadius: "7px",
                                    border: "1px solid #212121",
                                    background: this.state.pushType == "DisplayAd" ? "#3959F9" : "#141414",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                                    onClick={() => this.selectPushType("DisplayAd")}
                                    data-test-id="displayaddid"
                                >
                                    <Typography style={webStyle.selecttext}>
                                        Display Ad
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        {this.songsVideosscreen()}
                        <Box style={{ display: 'flex', flexDirection: "column", gap: "22px" }}>
                            <Typography style={webStyle.bluetext}>Target:  <span style={webStyle.selecttext}>Creative Type:</span></Typography>
                            <Typography style={webStyle.greytext}>Select the <span style={webStyle.smallbluetext}>creative types</span> you want your <br />
                                advertisement to show</Typography>
                            <Box style={{ display: "flex", gap: "2px", flexWrap: "wrap" }}>
                                {this.state.allcreativetypes.map((item, index) => (
                                    <button test-id="tags_name_trackdetails" type="button" className='button-upload-types'

                                        style={{ background: this.state.selectedCreativeType.includes(index) ? "#3959F9" : "#212121" }}
                                        onClick={() => this.selectCreativeType(index, 'creative')}
                                        data-test-id="creativetypeid"
                                    >
                                        {item.name}</button>
                                ))}

                            </Box>
                        </Box>
                        <Box style={{ display: 'flex', flexDirection: "column", gap: "22px" }}>
                            <Typography style={webStyle.bluetext}>Target:  <span style={webStyle.selecttext}>Genres</span></Typography>
                            <Typography style={webStyle.greytext}>Select the <span style={webStyle.smallbluetext}>genres </span>you want your <br /> advertisement to show in</Typography>
                            <Box style={{ display: "flex", gap: "2px", flexWrap: "wrap" }}>
                                {this.state.allgenrestypes.map((item, index) => (
                                    <button test-id="tags_name_trackdetails" type="button" className='button-upload-types'
                                        style={{ background: this.state.selectedGenreType.includes(index) ? "#3959F9" : "#212121" }}
                                        onClick={() => this.selectCreativeType(index, 'genre')}
                                        data-test-id="genretypeid"
                                    >
                                        {item.name}</button>
                                ))}

                            </Box>
                        </Box>
                    </Box>
                    <Box style={{ paddingTop: "10px", paddingBottom: "5px" }}>
                        <Hidden only={["lg", "md", "sm", "xl"]}>
                            <Button onClick={this.setActiveStepmobile.bind(this, "2")} style={{ background: "#FF0501", color: "white", width: "100%", borderRadius: "60px", height: "60px", fontFamily: "Inter", fontSize: "22px", textTransform: "capitalize", fontWeight: 700 }}>NEXT</Button>
                        </Hidden>
                    </Box>


                </Box>}


                <Box className='dividerAdsetup'>
                    <hr style={{
                        height: "100%",
                        width: "1px",
                        border: "1px solid #212121",
                        // transform: "scaleY(2.07)"
                    }}
                    ></hr>
                </Box>

                {this.profileScreen()}


                <Box className='dividerAdsetup'>
                    <hr

                        style={{
                            height: "100%",
                            width: "1px",
                            border: "1px solid #212121",

                        }}
                    ></hr>
                </Box>

                {this.bugdetDurationScreen()}
            </TrackAdvertisementContainer>

        )


    }
}

const webStyle = {
    selecttext: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "20px",
        color: "#FFFFFF"
    },
    dollarsmall: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "19.54px",
        color: "#FFFFFF"
    },
    dollartext: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "14.52px",
        color: "#FFFFFF"
    },
    redtext: {
        fontFamily: "Inter",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "21.78px",
        color: "#FF0501"
        // text-align: left;

    },
    videoImage: {
        width: "90px",
        height: "60px",
        borderRadius: "10px",
        objectFit: "cover" as "cover",
        border: "1px solid #212121"
    },
    splittexts: {
        fontStyle: "normal",
        lineHeight: "18px",
        letterSpacing: "normal",
        color: "#8f92a1",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
        fontStretch: "normal"
    },
    plusicon: {
        width: "40px",
        height: "40px",
        borderRadius: "40px",
        objectfit: "cover"

    },
    splittextwhites: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "18px",
        letterSpacing: "normal",
        color: "#F0F0F5",
        fontFamily: "Inter",
        fontStretch: "normal",
        fontStyle: "normal",
    },
    budgettext: {
        fontFamily: "Inter",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "16px",
        color: "#FFFFFF"
    },
    cpvtext: {
        fontFamily: "Inter",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "16px",
        color: "#FFFFFF"
    },
    budegtgreytext: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "16px",
        color: "#8F92A180",
    },
    bluetext: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "20px",
        color: "#3959F9"
    },
    alltypesbuttonbox: {
        display: "flex",
        flexDirection: "column" as "column",
        gap: "5px"
    },
    smallbluetext: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "20px",
        color: "#3959F9"
    },
    greytext: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "20px",
        color: "#8F92A1"
    },
    leftimage: {
        borderRadius: "12px",
        width: "60px",
        minWidth: "60px",
        height: "60px",
        border: "1px solid #212121"
    },
    selectaudiencetext: {
        fontFamily: "Inter",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "30px",
        color: "#FFFFFF"
    },
    Rightsidetabsbutton: {
        borderRadius: "30px",
        background: "#070707",
        display: "flex",
        justifyContent: "space-around",
        padding: "5px",
        border: "3px solid #FFFFFF",

    },
    tabbox: {
        width: "50%",
        display: 'flex',
        justifyContent: 'center'
    },
    tabaudiovideobutton: {
        fontSize: "16px",
        fontWeight: 800,
        color: "#8F92A1",
        borderRadius: "60px",
        width: "100%",
        textTransform: "capitalize" as "capitalize",
    },

    tabactiveaudiovideobutton: {
        fontSize: "16px",
        fontWeight: 800,
        color: "#F0F0F5",
        borderRadius: "60px",
        width: "100%",
        background: "#274af4",
        textTransform: "capitalize" as "capitalize",
    },
    imptext: {
        fontFamily: "Inter",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "21.78px",
        color: "rgb(162, 155, 155)"

    }
   



}

// Customizable Area End